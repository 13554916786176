<template>
  <div id="about">
    <ScrollDownBar :content="content.about" class="is-hidden-mobile"/>
    <VMV class="is-hidden-mobile"/>
    <VMVMobile class="is-hidden-desktop"/>
    <div id = "departments">
      <h1> {{ $t('departments.header') }} </h1>
      <RowSection v-for="(department, index) in departments" :key="index" :department = "department" :isReversed="isEven(index)"></RowSection>  
    </div>
    <PortretSlider/>
  </div>
</template>

<script>

import VMV from '@/components/heroes/VMVHero'
import VMVMobile from '@/components/heroes/VMWHeroMobile'
import RowSection from '@/components/cards/RowSection'
import PortretSlider from '@/components/sliders/PortretSlider'
import ScrollDownBar from '@/components/global-components/ScrollDownBar'

import {departments} from "@/assets/resources/departments"
import {content} from "@/assets/resources/scrollDownBarContent"

export default {
  components:{
    VMV,
    VMVMobile,
    RowSection,
    PortretSlider,
    ScrollDownBar
  },
  data () {
      return {
          departments,
          content
      }
  },
  methods: {
      isEven(index) {
          return index % 2 === 0;
      }
    }    
}
</script>

<style lang="scss" scoped>
#about {
  padding-top: 70px;
}

@media (min-width: 320px) and (max-width: 480px) {
  #departments > h1 {
    font-size: 24px;
    padding: 0 10px;
    margin-top: 10px;
  }
}
</style>
