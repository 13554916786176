let departments = [
    {
        title: "IT",
        content: "departments.it.content",
        overlayText: [
            'INFORMATION',
            'TECHNOLOGY'
        ],
        slides : [
            {
                image: "https://i.imgur.com/Tl6Yf6N.jpg"
            },
            {
                image: "https://i.imgur.com/xTbgo72.jpg"
            },
            {
                image: "https://i.imgur.com/Nlg6ZN5.jpg"
            }
        ],
        hoverimage: "https://i.imgur.com/9cCGC8v.jpg"
    },
    {
        title: "PR",
        content: "departments.pr.content",
        overlayText: [
            'PUBLIC',
            'RELATIONS'
        ],
        slides : [
            {
                image: "https://i.imgur.com/bwxJqk8.png"
            },
            {
                image: "https://i.imgur.com/AAurFLc.jpg"
            },
        ],
        hoverimage: "https://i.imgur.com/9cCGC8v.jpg"
    },
    {
        title: "FR",
        content: "departments.fr.content",
        overlayText: [
            'FUND',
            'RAISING'
        ],
        slides : [
            {
                image: "https://i.imgur.com/xKWKYrQ.jpg"
            },
            {
                image: "https://i.imgur.com/hgpNf9j.jpg"
            },
            {
                image: "https://i.imgur.com/7bmi86X.jpg"
            }
        ],
        hoverimage: "https://i.imgur.com/9cCGC8v.jpg"
    },
    {
        title: "HR",
        content: "departments.hr.content",
         overlayText: [
            'HUMAN',
            'RESOURCES'
        ],
        slides : [
            {
                image: "https://i.imgur.com/A6sbNND.jpg"
            },
            {
                image: "https://i.imgur.com/02kzCHE.jpg"
            },
            {
                image: "https://i.imgur.com/BgQOHah.jpg"
            }
        ],
        hoverimage: "https://i.imgur.com/9cCGC8v.jpg"
    },
        
]

export { departments };
