<template>
    <div id = "slider">
      <flickity ref="flickity" :options="flickityOptions">
         <div class="carousel-cell" v-for="(slide, index) in slides" :key="index">
           <img :src="slide.image">
         </div>  
      </flickity>
    </div>
</template>

<script>
import Flickity from 'vue-flickity'

export default {
  components: {
    Flickity
  },
  props : [
    'slides'
  ],
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: 2000
      }
    }
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },
    
    previous() {
      this.$refs.flickity.previous();
    }
  }
}
</script>


<style lang="scss">
#slider {
.carousel-cell {
  width: 100%;
  height: 30vh;
}

.flickity-viewport {
  margin-top: 60px;
}

#slider .flickity-page-dots {
  bottom: 40px !important;
}

#slider .flickity-page-dots .dot {
  background: #fff;
}

#slider .flickity-viewport {
  height: 30vh !important;
}
img {
  object-fit: cover;
  
}
.flickity-button {
  background: transparent;
}
.flickity-button:hover {
  background: transparent !important;
}
.flickity-button-icon {
  fill: white;
}
.flickity-button-icon {
  fill: white;
}
} 


</style>
