<template>
  <div class="scroll-bar">
    <div v-for="(item, index) in content" :key="index" class="scroll-bar-item">
      <a href="#" v-scroll-to="item.settings">{{$t(item.text)}}</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["content"]
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 15px;
  max-height: 8vh;
  position: fixed;
  top: 70px;
  width: 100%;
  background-color: #fff;
  z-index: 11;
  border-bottom: 1px solid #000;

  .scroll-bar-item {
    font-size: 1.5rem;
    a {
      color: #333;
    }
    width: 33%;


    a:hover {
      text-decoration: underline;
    }
  }
}
</style>