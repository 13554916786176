<template>
  <div id="portrait-slider">
    <h1>Board EESTEC LC Bucharest</h1>
    <div class="dot">
      <img
        v-for="(image, index) of images"
        :key="index"
        class="portrait"
        :src="image.img"
        :style="image.style()"
      />
    </div>
    <div class="function-box">
      <button class="direction-button" @click="rotateSlider(-1, true)">
        <b-icon icon="chevron-left" size="is-large"></b-icon>
      </button>
      <div class="portrait-description">
        <p>{{ images[currentIndex].name }}</p>
        <p>{{ $t(images[currentIndex].function) }}</p>
      </div>
      <button class="direction-button" @click="rotateSlider(1, true)">
        <b-icon icon="chevron-right" size="is-large"></b-icon>
      </button>
    </div>
    <div class="blurred-overlay"></div>
  </div>
</template>

<script>
import { setInterval } from "timers";
export default {
  data() {
      return {
          movementLock: false,
          autoSlider: true,
          currentIndex: 0,    
          images: [
            {
                img: "https://i.imgur.com/z8qSvHT.png",
                style: () => `transform: translateX(calc(${this.portraitDimensions.width} / -2)) translateY(calc(${this.portraitDimensions.height} / -2 - ${this.radius} / 2))`,
                angle: 0,
                index: 0,
                name: 'Alexandra Simion',
                function: 'board.hr'            
            },
            {
                img: "https://i.imgur.com/tSLr9Ox.png",
                style: () => `transform: translateX(calc(${this.portraitDimensions.width} / -2)) translateY(calc(${this.portraitDimensions.height} / -2 - ${this.radius} / 2))`,
                angle: 72,
                index: 1,
                name: 'Gabriela Nițoi',
                function: 'board.pr'            
            },
            {
                img: "https://i.imgur.com/GBHb6mk.png",
                style: () => `transform: translateX(calc(${this.portraitDimensions.width} / -2)) translateY(calc(${this.portraitDimensions.height} / -2 - ${this.radius} / 2))`,
                angle: 72 * 2,
                index: 2,
                name: 'Vlad Ungurean',
                function: 'board.cp'           
            },
            {
                img: "https://i.imgur.com/X7EAzhA.png",
                style: () => `transform: translateX(calc(${this.portraitDimensions.width} / -2)) translateY(calc(${this.portraitDimensions.height} / -2 - ${this.radius} / 2))`,
                angle: 72 * 3,
                index: 3,
                name: 'Florin Bărbălan',
                function: 'board.tr'            
            },
            {
                img: "https://i.imgur.com/lX0XDuB.png",
                style: () => `transform: translateX(calc(${this.portraitDimensions.width} / -2)) translateY(calc(${this.portraitDimensions.height} / -2 - ${this.radius} / 2))`,
                angle: 72 * 4,
                index: 4,
                name: 'Raluca Loghin',
                function: 'board.president'            
            }
        ]
      }
    },
  computed: {
    /*
            These constants are used to position the photographs alongside a circle that rotates every few seconds.
            The portrait dimensions are the dimensions of the photograph. They are used in the <script> section to calculate the rotation. They are also present in CSS.
        */
    portraitDimensions: () => {
      return {
        height: "250px",
        width: "200px"
      };
    },
    /*
            The radius is the radius of the circle on which the photos are placed on. The rotation coeficients are the coordinates of the photos ON the circle.
            There are 2 ways to add space between the photos. 
            
            1.Increasing the radius will increase the space between ALL photos EVENLY.
            2. Adding an offset to the individual coeficients, to move a single photo from its initial position on the circle.

            All the changes are applied automatically. As soon as you modify a number here, the photo will move
        */
    radius: () => {
      return "400px";
    },
    rotationCoeficients: () => {
      return [
        {
          x: 0  ,
          y: 0.1
        },
        {
          x: 0.951,
          y: 0.309
        },
        {
          x: 0.887,
          y: 1.2
        },
        {
          x: -0.887,
          y: 1.2
        },
        {
          x: -0.951,
          y: 0.309
        }
      ];
    }
  },
  methods: {
    /* function to rotate the entire slider */
    rotateSlider: function(direction, stopAutoSlider) {
      /* to avoid spam rotations there exists a movementLock. If the slider is in the motion of rotating it cannot rotate again.
            Also to avoid rotation while the user is tabbed out we condition the rotation by !document.hidden */
      if (!this.movementLock && !document.hidden) {
        this.movementLock = true; // lock the movement
        setTimeout(() => {
          this.movementLock = false; // after 1 second release the lock
        }, 1000);
        this.images.forEach(image => {
          this.rotateImage(direction, image); // rotate each individual image
        });
        if (stopAutoSlider && this.autoSlider == true) {
          // if the rotation function is called from one of the arrow buttons stop the auto sliding for 8 seconds
          this.autoSlider = false;
          setTimeout(() => {
            this.autoSlider = true;
          }, 8000);
        }
      }
    },
    rotateImage: function(direction, image) {
      image.index += 1 * direction;
      image.angle += 72 * direction;
      if (image.index > 4) {
        image.index = 0;
      } else if (image.index < 0) {
        image.index = 4;
      }
      if (image.index === 0) {
        this.currentIndex = this.images.indexOf(image);
      }

      let offset = 0;
      if (image.index === 4) {
        offset = 40;
      } else if (image.index === 1) {
        offset = -40;
      } 
      image.style = () =>
        `transform: translateX(calc(${this.portraitDimensions.width} / -2 + ${
          this.radius
        } * ${this.rotationCoeficients[image.index].x})) translateY(calc(${
          this.portraitDimensions.height
        } / -2 - ${this.radius} / 2 + ${this.radius} * ${
          this.rotationCoeficients[image.index].y
        })) rotate(${image.angle + offset}deg)`;
    }
  },
  mounted() {
    this.images.forEach(image => {
      this.rotateImage(1, image);
    });
    setInterval(() => {
      if (this.autoSlider) {
        this.rotateSlider(1);
      }
    }, 3000);
  }
};
</script>

<style lang="scss" scoped>
$portrait-height: 250px;
$portrait-width: 200px;
$font-size: 22px;

#portrait-slider {
  width: 100%;
  height: 550px;
  overflow: hidden;
  position: relative;
  padding-top: 80px;
}

.dot {
  width: 1px;
  height: 1px;
  transform: translateX(50vw) translateY(320px);
  overflow: visible;
}

.portrait {
  width: $portrait-width;
  height: $portrait-height;
  object-fit: cover;
  max-width: none;
  transition: 1s all;
  position: absolute;
  z-index: 1;
  transition: filter all;
}

.portrait:hover {
  filter: none;
}

.blurred-overlay {
  height: 80px;
  width: 100%;
  top: calc(100% - 80px);
  position: absolute;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 2;
}

.function-box {
  width: 50%;
  text-align: center;
  z-index: 10;
  transform: translateY(300px);
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.portrait-description {
  min-width: 150px;

  p:nth-child(2) {
    font-weight: bold;
  }
}

.direction-button {
  background-color: transparent;
  border: 0;
  margin: 0 10px;
  height: 52px;
  outline: 0;
}

.direction-button:hover {
  background-color: transparent !important;
  border-radius: 50%;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #portrait-slider > h1 {
    font-size: 24px;
  }

  .function-box {
    width: 80%;
  }
}
</style>