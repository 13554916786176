<template>
    <div class="rowSection">
        <!--<button class="button" @click="hideOverlay()">Click</button>-->
       <div class="overlay" :class="{ hiddenDiv: isHidden }" v-if="overlayVisible">
            <h1 class= "overlay-text"> {{ department.overlayText[0] }} </h1>
            <h1 class= "overlay-text"> {{ department.overlayText[1] }} </h1>
       </div>  
       <div class="columns" :class="{ reverse: isReversed }">
           <div class="column is-8" v-bind:class="{'has-text-right':isReversed, 'has-text-left':!isReversed}">
               <h5 v-if="department.title" class="department-title is-size-3" v-bind:class="{'has-text-right':isReversed, 'has-text-left':!isReversed}"> {{ $t(department.title) }} </h5>
               <p v-html="$t(department.content)"></p>   
            </div>
            <div class="column is-4">
                <figure class="image is-16by9">
                   <RowSectionslider :slides = "department.slides"></RowSectionslider> 
                </figure>  
           </div>
       </div>
        <div v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"></div> 
    </div>
</template>

<script>
import RowSectionslider from '@/components/sliders/RowSectionslider.vue'

export default { 
    components: {
        RowSectionslider
    },
    data() {
        return {
            isHidden: window.innerWidth > 600 ? false : true,
            overlayVisible: window.innerWidth > 600 ? true : false,
            intersectionOptions: {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                thresholds: [0]
            }
        }
    },
    props: [
        'isReversed',
        'department',
    ],
    methods: {
        mouseOver: function(){
            this.active = !this.active;   
        },
        hideOverlay: function() {
            this.isHidden = true;
            setTimeout(() => {
                this.overlayVisible = false;
            }, 1500)
        },
        onWaypoint ({ going, direction }) {
            if (going === this.$waypointMap.GOING_IN) {
                this.hideOverlay();
            }
        }
    },
    created() {
        // console.log(window.innerWidth);
        // console.log(this.overlayVisible);
    }
}
</script>

<style lang="scss" scoped>

.rowSection {

    & { 
        margin-top: 5%;
        margin-left: 10%;
        margin-right: 10%;
        padding-left: 3%;
        padding-right: 3%;
        position: relative;
    }

    .columns {
        margin-bottom: 2rem;
        margin-top: 2rem;
        justify-content: space-between;
    }

    .overlay {
        position: absolute;
        opacity: 1;
        height: 100%;
        width: 100%;
        z-index: 10;
        transition: 1.8s all;
        background-color: #fff;
        opacity: 0.9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .overlay-text {
        font-size: 50px;
        text-align: left;
        font-weight: 100;
    }

    .overlay-text:nth-child(2) {
        color: $primary-color;
    } 

    .hiddenDiv {
        // display: none;
        opacity: 0;
    }

    h5 {
        margin-bottom: 3%;
    }

    .image {
        padding-top: 0;
    }

    .reverse {
        flex-direction: row-reverse;
    }

    .challenge-title {
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 600px) {
        .columns {
            display: flex;
            flex-direction: column-reverse;
        }

        & {
            margin-left: 2%;
            margin-right: 2%;
        }

        .has-text-right, .has-text-left {
            text-align: center !important;
        }
    }
}



</style>
