const content = {
  about: [
    {
      text: "VMV",
      settings: {
        el: "#vmv",
        offset: -100
      }
    },
    {
      text: "scrollBar.about.departamente",
      settings: {
        el: "#departments",
        offset: -100
      }
    },
    {
      text: "Board",
      settings: {
        el: "#portrait-slider",
        offset: -100
      }
    }
  ],
  international: [
    {
      text: "scrollBar.international.map",
      settings: {
        el: "#international-map-hero",
        offset: -150
      }
    },
    {
      text: "scrollBar.international.events",
      settings: {
        el: "#international-events",
        offset: -100
      }
    },
    {
      text: "scrollBar.international.teams",
      settings: {
        el: "#international-slider",
        offset: -150
      }
    }
  ]
};

export { content };
