<template>
    <div id = "vmv">
        <div class="aboutVMV">
            <parallax :speedFactor="0.3">
                <img :src="img">
            </parallax>
            <div class="aboutCards">
                <AboutCard />
            </div>
            <div class="red">
                
            </div>
        </div> 
    </div>
</template>

<script>

import AboutCard from '@/components/cards/AboutCard'
import Parallax from 'vue-parallaxy'

export default {   
    components:{
        AboutCard,
        Parallax
    },
    data() {
        return {
            img: "https://i.imgur.com/rwbRUWx.jpg",
        }
    }
}
</script>

<style lang="scss" scoped>

#vmv{
    position: relative;
}

.aboutVMV{
    background-size: cover;
    margin-bottom: 0px;
    top: 100px;
}

.red{
    height: 300px;
    background-size: cover;
    background-color: $primary-color;
}

.aboutCards{
    overflow: hidden;
    background-color: transparent; 
    bottom:100px;
    position: absolute;
    width: 100%;
}
</style>